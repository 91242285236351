body {
  background-color: hsl(18, 81%, 94%);
  color: hsl(32, 30%, 24%);
  line-height: 1.5;
  font-family: 'Open Sans';
}

a {
  color: hsl(162, 70%, 40%);
  font-weight: 700;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: none;
}

.serif {
  font-family: 'Neuton';
}

h1 {
  font-size: calc(40px + 1.5vmin);
  font-weight: 500;
  font-family: 'Abril Fatface';
}

h2 {
  font-family: 'Neuton';
  font-style: italic;
  font-weight: 300;
  font-size: calc(20px + 1.5vmin);
}

.f4 {
  font-size: calc(.9rem + .4vw);
}

ul {
  padding: 0;
}

li {
  margin: .75rem 0;
  padding: 0;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.customlist li {
  position: relative;
  margin: .5rem 0;
}

.quotelist li {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 1.5rem;
}
.quotelist li:before {
  content: '.';
  color: transparent;
  position: absolute;
  left: -2rem;
  border-right: 1rem solid white;
  border-radius: 0 0 2rem 0;
  bottom: 0;
  width: 2rem;
}

.bulletlist li:before {
  content: '»';
  position: absolute;
  left: -1.25rem;
}

@media screen and (min-width: 30em) {
  .flex-40-ns {
    flex: 0 1 40%;
  }
  .flex-50-ns {
    flex: 0 1 50%;
  }
  .flex-60-ns {
    flex: 0 1 60%;
  }
}