body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: hsl(18, 81%, 94%);
  color: hsl(32, 30%, 24%);
  line-height: 1.5;
  font-family: 'Open Sans';
}

a {
  color: hsl(162, 70%, 40%);
  font-weight: 700;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: none;
}

.serif {
  font-family: 'Neuton';
}

h1 {
  font-size: calc(40px + 1.5vmin);
  font-weight: 500;
  font-family: 'Abril Fatface';
}

h2 {
  font-family: 'Neuton';
  font-style: italic;
  font-weight: 300;
  font-size: calc(20px + 1.5vmin);
}

.f4 {
  font-size: calc(.9rem + .4vw);
}

ul {
  padding: 0;
}

li {
  margin: .75rem 0;
  padding: 0;
}

.App-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.customlist li {
  position: relative;
  margin: .5rem 0;
}

.quotelist li {
  background: white;
  padding: .75rem 1.5rem;
  border-radius: 1.5rem;
}
.quotelist li:before {
  content: '.';
  color: transparent;
  position: absolute;
  left: -2rem;
  border-right: 1rem solid white;
  border-radius: 0 0 2rem 0;
  bottom: 0;
  width: 2rem;
}

.bulletlist li:before {
  content: '\BB';
  position: absolute;
  left: -1.25rem;
}

@media screen and (min-width: 30em) {
  .flex-40-ns {
    -webkit-flex: 0 1 40%;
            flex: 0 1 40%;
  }
  .flex-50-ns {
    -webkit-flex: 0 1 50%;
            flex: 0 1 50%;
  }
  .flex-60-ns {
    -webkit-flex: 0 1 60%;
            flex: 0 1 60%;
  }
}
